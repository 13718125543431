.dashboard-filter {
    background-color: #cfd8dc;
    /* padding: 30px; */
    padding: 20px 20px;
    margin: 10px;
    border-radius: 5px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-around;
    /* height: 130px; */
}
.row {
    margin-top: auto;
}

/* .col-lg-6.btn {
    width: 30vw;
} */