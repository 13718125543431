.hums-main {
  height: auto;
}

.hums-container {
  margin: 5vh auto;
  background-color: #192a39;
  padding: 20px;
  border-radius: 5px;
  max-width: 480px;
}

.hums-container button {
  width: 100%;
  margin-top: 16px;
}

.hums-container h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 100;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-align: center;
}

.hums-container h3 {
  color: #fff;
  font-size: 2rem;
}

.hums-container .input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.hums-container p {
  color: #fff;
}

.radio-input-wrapper {
  display: flex;
  width: 100%;
  color: #fff;
  margin-top: 4px;
}

.radio-input-wrapper label {
  font-weight: 400;
  margin-left: 4px;
}

.input-wrapper > label,
.radio-input-wrapper > label {
  width: 100%;
  color: rgba(255, 255, 255, 0.75);
  text-align: left;
}

.go-back-wrapper {
  margin-top: 8px;
}

.go-back-wrapper button {
  width: 100%;
  color: #fff;
  background: transparent;
}
