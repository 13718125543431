.position-row {
    display: flex;
    flex: 1;
    flex-direction: row;
    
}
.position-coord {
    background-color: #FFFFFF;
    background-image: none;
    border: 1px solid #e5e6e7;
    border-radius: 1px;
    color: inherit;
    padding: 6px 12px;
    -webkit-transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    width: 50%;
    font-size: 14px;
    text-align: center;
}
.input-button-row {
    display: flex;
    flex: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: space-between;
    width: 100%;
    padding: 0px;
    /* align-items: center; */
}

.input-button-row .btn{
    margin-bottom: 15px;
}