/* Tirar borda do input de multi-select */

.multi-select>div {
    border-left: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: #e5e6e7;
}

/* Tirar separador do multi-select */

.multi-select>div>div>span {
    background-color: transparent !important;
}

/* Deixar as opções de seleção no topo do site */

#react-select-2-listbox {
    z-index: 999999;
}

span.mec-label {
    width: 100%;
    font-size: 14px;
    font-weight: bold;
}

.mec-input {
    margin-top: 8px
}

.recent-mec {
    cursor: pointer;
    text-decoration: underline;
}


/* change table fonts */
#identify > td {
}

.icon-mr {
    margin-right: 8px;
}