.svg {
    width: 32%;
    height: 100%;
    min-width: 300px;
}
.svg>svg{
    width: 100%;
    margin-top: auto;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 3px 3px 5px #888;
}