@media screen and (max-width: 990px) {
    .col-md-6 {
        padding: 18px 0 0 0 !important;
    }
}

tbody.evaluation > tr > td { 
    font-size: 14px;
}

.td-center {
    width: 100%;
    display: flex;
    justify-content: center;
}

.td-pr {
    padding-right: 32px !important;
}