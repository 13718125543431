body,
html {
  height: 100%;
}

.main {
  background-color: #2f4050 !important;
  width: 100%;
  height: 100vh;
}
.loginContainer {
  margin: auto;
  background-color: #192a39;
  padding: 20px;
  border-radius: 5px;
  max-width: 300px;
  margin-top: 10vw;
}

.loginContainer img {
  max-height: 100px;
}
.loginContainer h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 100;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-align: center;
}
.loginContainer p {
  color: #fff;
}
