@media screen and (max-width: 990px) {
  .col-md-4 {
    padding: 18px 0 0 0 !important;
  }

  .fix-padding {
    padding: 0 !important;
  }

  #search {
    padding-left: 0 !important;
  }
}

.card-body {
  min-height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
  background-color: #001f3f;
  -webkit-box-shadow: 2px 4px 4px 0px rgba(25,42,57,1);
  -moz-box-shadow: 2px 4px 4px 0px rgba(25,42,57,1);
  box-shadow: 2px 4px 4px 0px rgba(25,42,57,1);
  border-radius: 5px;
  cursor: pointer;
}

.card-text {
  font-size: 16px;
  color: #fff;
}

.card-text.title {
  font-size: 24px;
  font-weight: bold;
}

.card-body span {
  font-size: 16px;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.4s ease-in-out;
}

.card-body span p {
  margin-top: 8px;
}

.card-body span:hover {
  background-color: rgba(36, 53, 68, 0.7);
}