@media screen and (max-width: 990px) {
    .col-md-6 {
        padding: 18px 0 0 0 !important;
    }
}
/* Tirar borda do input de select */

.mec-select>div {
    border-left: none !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: #e5e6e7;
}

/* Tirar separador do select */

.mec-select>div>div>span {
    background-color: transparent !important;
}

/* Deixar as opções de seleção no topo do site */

#react-select-2-listbox {
    z-index: 999999;
}

.topic-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 32px;
    padding: 16px;
    background-color: #fff;
}

.stripped-topic-wrapper:nth-of-type(odd) {
    background-color: #eeeeee;
}